import { defineStore } from 'pinia'

export interface Product {
  id: number
  delivery_date: string
  name: string
  price: number
  stock: string
  quantity_identifier: string
}

export interface DeliveryDate {
  id: number
  delivery_date: string
  delivery_city_name: string
  products: Product[]
}

interface DatesAndProductsState {
  deliveryDates: DeliveryDate[]
  selectedDeliveryDateId: number | null
  selectedDateProducts: Product[]
}

export const useDatesAndProductsStore = defineStore('datesAndProducts', {
  state: (): DatesAndProductsState => ({
    deliveryDates: [],
    selectedDeliveryDateId: null,
    selectedDateProducts: [],
  }),
  actions: {
    setDeliveryDates(data: DeliveryDate[]) {
      this.deliveryDates = data
    },
    setSelectedDeliveryDate(date: number) {
      this.selectedDeliveryDateId = date
      // Set the products of the selected date
      const matchedDate = this.deliveryDates.find(
        (dateObj: DeliveryDate) => dateObj.id === date
      )
      if (matchedDate) {
        this.selectedDateProducts = matchedDate.products
      } else {
        this.selectedDateProducts = []
      }
    },
    resetSelectedDate() {
      this.selectedDeliveryDateId = null
      this.selectedDateProducts = []
    },
  },
  getters: {
    getDates(): DeliveryDate[] {
      return this.deliveryDates
    },
    getSelectedDateProducts(): Product[] {
      return this.selectedDateProducts
    },
    // TODO: Refactor this to more generic
    getSelectedDeliveryDate(): string {
      const matchedDate = this.deliveryDates.find(
        (dateObj: DeliveryDate) => dateObj.id === this.selectedDeliveryDateId
      )
      if (matchedDate) {
        const date = new Date(matchedDate.delivery_date)
        return new Intl.DateTimeFormat('sl-SI', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }).format(date)
      }
      return ''
    },
    getSelectedDeliveryCity(): string {
      const matchedDate = this.deliveryDates.find(
        (dateObj: DeliveryDate) => dateObj.id === this.selectedDeliveryDateId
      )
      if (matchedDate) {
        return `${matchedDate.delivery_city_name}`
      }
      return ''
    },
    // TODO: Refactor this to more generic
    getSelectedDeliveryNameAndDate(): string {
      const matchedDate = this.deliveryDates.find(
        (dateObj: DeliveryDate) => dateObj.id === this.selectedDeliveryDateId
      )
      if (matchedDate) {
        const date = new Date(matchedDate.delivery_date)
        const formattedDate = new Intl.DateTimeFormat('sl-SI', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }).format(date)
        return `${matchedDate.delivery_city_name} - ${formattedDate}`
      }
      return ''
    },
  },
})
