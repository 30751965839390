import { defineStore } from 'pinia'

interface Step {
  title: string
  component?: any
  icon?: string
}

interface StepperState {
  currentStep: number
  steps: Step[]
  telephoneValid: boolean
  userQuestions: string | null
  notificationPreference: boolean | null
  termsAgreed: boolean
  emailValid: boolean
}

export const useStepperStore = defineStore('stepper', {
  state: (): StepperState => ({
    currentStep: 0,
    steps: [
      { title: 'Informacije', component: 'Introduction' },
      { title: 'Izberi izdelke', component: 'Step1', icon: 'cart-arrow-down' },
      { title: 'Vstavi podatke', component: 'Step2', icon: 'user' },
      { title: 'Naroči', component: 'Step3', icon: 'circle-check' },
    ],
    telephoneValid: false,
    userQuestions: null,
    notificationPreference: true,
    termsAgreed: false,
    emailValid: false,
  }),
  actions: {
    setSteps(newSteps: Step[]) {
      this.steps = newSteps
    },
    setCurrentStep(step: number) {
      this.currentStep = step
    },
    next() {
      if (this.currentStep < this.steps.length - 1) this.currentStep++
    },
    prev() {
      if (this.currentStep > 0) this.currentStep--
    },
    setTelephoneValidity(validity: boolean) {
      this.telephoneValid = validity
    },
    setUserQuestion(question: string) {
      this.userQuestions = question
    },
    setEmailValidity(validity: boolean) {
      this.emailValid = validity
    },
    setNotificationPreference(preference: boolean) {
      this.notificationPreference = preference
    },
    setTermsAgreed(agreed: boolean) {
      this.termsAgreed = agreed
    },
  },
  getters: {
    currentComponent(): any {
      return this.steps[this.currentStep].component
    },
    canGoBack(): boolean {
      return this.currentStep > 0
    },
  },
})
