<template>
  <div class="relative flex items-center gap-4">
    <!-- Product Image -->
    <nuxt-img
      provider="s3"
      :src="'/products/' + product.image"
      alt="Product Image"
      class="object-cover w-20 h-20 rounded-xl"
    />
    <!-- Product Details -->
    <div class="flex-grow flex flex-col gap-0.5">
      <!-- Product Name -->
      <span class="font-bold font-lg">{{ product.name }}</span>

      <!-- Product Price -->
      <span class="text-sm">
        Okvirna Cena: {{ product.totalPrice }} €
        <!-- Unit Price -->
        (<span class="text-gray-500">
          {{ product.price }} € / {{ product.quantity_identifier }}</span
        >)
      </span>
    </div>

    <!-- Quantity Controls -->
    <div
      class="flex items-center gap-2 border border-gray-200 rounded-xl p-0.5"
    >
      <!-- Added flex and items-center -->
      <button
        class="px-1 py-0.5 border border-red-500 rounded-lg text-xl font-semibold hover:bg-red-100 transition-colors duration-300 ease-in-out"
        @click="decreaseQuantity"
      >
        <icon-bi:dash style="color: red"></icon-bi:dash>
      </button>
      <span class="border-gray-200 px-2 py-1 flex items-center">
        <!-- Added flex and items-center here as well -->
        {{ product.quantity }}
        <span class="text-gray-400 pl-1">{{
          product.quantity_identifier
        }}</span>
      </span>
      <button
        class="px-1 py-0.5 border border-green rounded-lg text-xl font-semibold hover:bg-emerald-100 transition-colors duration-300 ease-in-out"
        @click="increaseQuantity"
      >
        <icon-bi:plus style="color: green"></icon-bi:plus>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGtag } from 'vue-gtag-next'
import { useBasketStore } from '@/stores/basket'
import { useStepperStore } from '@/stores/stepper'
const gtag = useGtag()

const props = defineProps<{
  product: {
    id: string
    name: string
    quantity: number
    price: number
    totalPrice: number
    image: string
    quantity_identifier: string
  }
}>()

const store = useBasketStore()
const stepperStore = useStepperStore()

const increaseQuantity = () => {
  store.increaseItemQuantity(props.product.id)
  gtag.event('increase_prod_qt', {
    event_category: 'Ecommerce',
    event_label: 'Increase product quantity',
    value: props.product.price,
    items: [
      {
        id: props.product.id.toString(),
        name: props.product.name,
        quantity: 1,
        price: props.product.price,
      },
    ],
  })
}

const decreaseQuantity = () => {
  store.decreaseItemQuantity(props.product.id)
  if (store.isBasketEmpty()) {
    gtag.event('empty_basket', {
      event_category: 'Ecommerce',
      event_label: 'Removed last product from basket',
      value: props.product.price,
      items: [
        {
          id: props.product.id.toString(),
          name: props.product.name,
          quantity: 1,
          price: props.product.price,
        },
      ],
    })
    stepperStore.setCurrentStep(1)
  }

  gtag.event('decrease_prod_qt', {
    event_category: 'Ecommerce',
    event_label: 'Decrease product quantity',
    value: props.product.price,
    items: [
      {
        id: props.product.id.toString(),
        name: props.product.name,
        quantity: 1,
        price: props.product.price,
      },
    ],
  })
}
</script>
