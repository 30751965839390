<template>
  <!-- Basket header -->
  <div class="flex justify-between">
    <div class="flex items-center">
      <font-awesome-icon
        :icon="['fas', 'receipt']"
        class="icon-right-anim text-green mr-2 text-lg"
      />
      <h2 class="text-lg font-semibold leading-5">Predogled naročila</h2>
    </div>
    <!-- Basket footer -->
    <div v-if="products.length !== 0" class="">
      <button
        class="border border-red-500 px-4 py-1 rounded-2xl text-sm font-semibold text-red-500 hover:bg-red-100 transition-colors duration-300 ease-in-out"
        @click="clearBasket"
      >
        Počisti košarico
      </button>
    </div>
  </div>

  <!-- Basket content -->
  <div v-if="products.length" class="py-2 gap-5 flex flex-col mt-4">
    <ShoppingBasketProduct
      v-for="product in products"
      :key="product.id"
      :product="product"
    />

    <!-- Discount Section
  <div class="px-4 py-2 border-b border-gray-300">
    <span class="font-medium">Koda za popust:</span>
    <span class="ml-2 text-green-500">Discount</span>
  </div> -->

    <!-- Total Price Section -->
    <hr />
    <div class="flex gap-4 justify-between">
      <div class="font-bold">Za plačilo:</div>
      <div class="font-bold text-xl">{{ totalAmount }} €</div>
    </div>

    <hr />
    <div v-if="deliveryPlace" class="flex gap-4 justify-between">
      <div class="font-bold font-sm">Kraj dostave:</div>
      <div class="font-bold">{{ deliveryPlace }}</div>
    </div>
  </div>
  <div v-else class="mt-6 text-gray-500">Vaša košarica je prazna</div>
</template>

<script setup lang="ts">
import { useBasketStore } from '@/stores/basket'
import { useDatesAndProductsStore } from '@/stores/datesAndProducts'
import { useStepperStore } from '~/stores/stepper'

const basketStore = useBasketStore()
const stepperStore = useStepperStore()
const deliveryStore = useDatesAndProductsStore()
const products = computed(() => basketStore.items)
const totalAmount = computed(() => basketStore.totalValue)
const deliveryPlace = computed(
  () => deliveryStore.getSelectedDeliveryNameAndDate
)
const clearBasket = () => {
  basketStore.$reset()
  stepperStore.setCurrentStep(1)
}
</script>
